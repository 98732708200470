import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectFilterWords,
  selectFilterLevel,
  selectFilterTopic,
  selectFilterLanguage,
  selectFilterType,
  selectFilterSource,
} from 'data/filter/selectors';
import { actions as filterActions } from 'data/filter/slice';

import { Icon } from 'components/atoms/Icon';
import { Typography } from 'components/atoms/Typography';
import { filterOptions } from './constants';
import { Wrapper, Option, Checkbox, Checkmark } from './wrappers';
import messages from './messages';

export const FilterOptions = ({ filter }) => {
  const dispatch = useDispatch();
  const language = useSelector(selectFilterLanguage);
  const source = useSelector(selectFilterSource);
  const type = useSelector(selectFilterType);
  const level = useSelector(selectFilterLevel);
  const words = useSelector(selectFilterWords);
  const topic = useSelector(selectFilterTopic);

  const getAction = option => {
    switch (filter) {
      case 'language':
        return dispatch(filterActions.applyLanguage(option));
      case 'source':
        return dispatch(filterActions.applySource(option));
      case 'type':
        return dispatch(filterActions.applyType(option));
      case 'level':
        return dispatch(filterActions.applyLevel(option));
      case 'words':
        return dispatch(filterActions.applyWords(option));
      case 'topic':
        return dispatch(filterActions.applyTopic(option));
      default:
        return null;
    }
  };
  const getPressed = option => {
    switch (filter) {
      case 'language':
        return language.includes(option);
      case 'source':
        return source.includes(option);
      case 'type':
        return type.includes(option);
      case 'level':
        return level.includes(option);
      case 'words':
        return words === option;
      case 'topic':
        return topic.includes(option);
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      {!!filterOptions[filter] &&
        filterOptions[filter].map(option => (
          <Option
            key={`${filter}:${option}`}
            onClick={() => getAction(option)}
            pressed={getPressed(option)}
          >
            <Checkbox>
              {getPressed(option) && (
                <Checkmark>
                  <Icon variant="check-red" />
                </Checkmark>
              )}
            </Checkbox>
            <Typography
              variant="text-md"
              as="span"
              label={
                ['language', 'source', 'type', 'topic', 'level'].includes(
                  filter,
                ) ? (
                  <FormattedMessage {...messages[option]} />
                ) : option === '2500+' ? (
                  <FormattedMessage {...messages.highWords} />
                ) : (
                  <FormattedMessage
                    {...messages.words}
                    values={{ number: option }}
                  />
                )
              }
            />
          </Option>
        ))}
    </Wrapper>
  );
};

FilterOptions.propTypes = {
  filter: PropTypes.string.isRequired,
};
