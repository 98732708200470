import { defineMessages } from 'react-intl';

export default defineMessages({
  accessTo: {
    id: 'app.components.atoms.CheckItem.accessTo',
    defaultMessage: `Access to books or tablets`,
  },
  timeTo: {
    id: 'app.components.atoms.CheckItem.timeTo',
    defaultMessage: `Time to read & write`,
  },
  teachers: {
    id: 'app.components.atoms.CheckItem.teachers',
    defaultMessage: `Teachers who love to read`,
  },
  instruction: {
    id: 'app.components.atoms.CheckItem.instruction',
    defaultMessage: `Reading & writing instruction`,
  },
  literacy: {
    id: 'app.components.atoms.CheckItem.literacy',
    defaultMessage: `The chance to build on literacy strengths`,
  },
  talk: {
    id: 'app.components.atoms.CheckItem.talk',
    defaultMessage: `Time to talk about books & stories`,
  },
});
