import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 12px;
  background-color: ${p => p.theme.color.white100};
  z-index: 1;

  /* react-modal Transitions */
  .ReactModal__Content & {
    opacity: 0.85;
    transform: translateY(16px);
    transition: all 150ms ease-in-out;
  }

  .ReactModal__Content--after-open & {
    opacity: 1;
    transform: translateY(0);
    width: 100vw;
    height: 100vh;
  }

  .ReactModal__Content--before-close & {
    opacity: 0.85;
    transform: translateY(16px);
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 12px 12px 0;
  position: relative;

  > button {
    z-index: 2;
  }
`;
