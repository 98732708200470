import { defineMessages } from 'react-intl';

export default defineMessages({
  loading: {
    id: 'app.containers.BookViewer.loading',
    defaultMessage: 'loading...',
  },
  pageCount: {
    id: 'app.containers.BookViewer.page-count',
    defaultMessage: 'page {pageNumber} of {pageCount}',
  },
});
