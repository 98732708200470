import styled from 'styled-components';

export const ContentWrapper = styled.div`
  max-width: 720px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; /* Center buttons horizontally */
  max-width: 100%;
  margin: 28px 0;

  > button {
    margin: 12px;
  }
`;
