import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './wrappers';

export const Typography = ({ label, ...props }) => (
  <Wrapper {...props}>{label || props.children}</Wrapper>
);

Typography.defaultProps = {
  as: 'div',
  children: null,
  fontColor: '',
  variant: 'text-md',
  weight: 'normal',
};

Typography.propTypes = {
  as: PropTypes.oneOf(['div', 'h1', 'h2', 'h3', 'h4', 'p', 'span']),
  children: PropTypes.node,
  fontColor: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'text-md',
    'text-lg',
    'text-sm',
    'text-xs',
  ]),
  weight: PropTypes.oneOf(['normal', 'medium', 'bold']),
};
