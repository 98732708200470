import { createSelector } from '@reduxjs/toolkit';

import { initialState, sliceKey } from './slice';

const selectDomain = state => state[sliceKey] || initialState;

export const selectOpenBook = createSelector(
  [selectDomain],
  state => state.openBook,
);
