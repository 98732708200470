import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { useSwipeable } from 'react-swipeable';
import { FormattedMessage } from 'react-intl';
import { ThreeDots } from 'react-loader-spinner';
import { base64ToBlob } from 'shared/functions/base64ToBlob';

import { Typography } from 'components/atoms/Typography';
import { Button } from 'components/atoms/Button';
import {
  PageWrapper,
  HeaderWrapper,
  LoadingWrapper,
  Wrapper,
} from './wrappers';
import messages from './messages';

const BookViewer = ({ openBook }) => {
  const [pageCount, setPageCount] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (openBook?.pdf && !file) {
      const pdf = base64ToBlob(openBook.pdf, 'application/pdf');
      const fileURL = URL.createObjectURL(pdf);
      setFile(fileURL);
    }
  }, [openBook, file]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(numPages);
  };

  const onLoadError = error => {
    // console.log('Error while loading document! ', error.message, 'file', file); // eslint-disable-line
    const pdf = base64ToBlob(openBook.pdf, 'application/pdf');
    const fileURL = URL.createObjectURL(pdf);
    setFile(fileURL);
    setPageCount(0);
  };

  const prevPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const nextPage = () => {
    if (pageNumber < pageCount) setPageNumber(pageNumber + 1);
  };

  const swipeHandlers = useSwipeable({
    onSwipedRight: eventData => {
      prevPage();
    },
    onSwipedLeft: eventData => {
      nextPage();
    },
  });

  useEffect(() => {
    const listener = e => {
      if (e.keyCode === 37) prevPage();
      if (e.keyCode === 39) nextPage();
    };
    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, [pageNumber]); // eslint-disable-line

  return (
    <Wrapper {...swipeHandlers}>
      {!!pageCount && (
        <HeaderWrapper>
          <Button
            variant="icon-sm"
            iconLeft="arrow-left"
            label=""
            disabled={pageNumber === 1}
            onClick={prevPage}
          />
          <Typography
            variant="text-md"
            label={
              <FormattedMessage
                {...messages.pageCount}
                values={{ pageNumber, pageCount }}
              />
            }
          />
          <Button
            variant="icon-sm"
            iconLeft="arrow-right"
            label=""
            disabled={pageNumber === pageCount}
            onClick={nextPage}
          />
        </HeaderWrapper>
      )}
      {file ? (
        <Document
          loading={
            <LoadingWrapper>
              <ThreeDots
                width="100"
                color="#002664"
                ariaLabel="loading"
                visible
              />
            </LoadingWrapper>
          }
          onLoadSuccess={onDocumentLoadSuccess}
          {...{ file, onLoadError }}
        >
          <PageWrapper>
            <Page
              loading={
                <LoadingWrapper>
                  <ThreeDots
                    width="100"
                    color="#002664"
                    ariaLabel="loading"
                    visible
                  />
                </LoadingWrapper>
              }
              pageNumber={pageNumber}
            />
          </PageWrapper>
        </Document>
      ) : (
        <LoadingWrapper>
          <ThreeDots width="100" color="#002664" ariaLabel="loading" visible />
        </LoadingWrapper>
      )}
    </Wrapper>
  );
};

BookViewer.propTypes = {
  openBook: PropTypes.shape({
    code: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
    level: PropTypes.string,
    topic: PropTypes.string,
    total_words: PropTypes.number,
    type: PropTypes.string,
    pdf: PropTypes.string,
    png: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // eslint-disable-line
  }).isRequired,
};

export default BookViewer;
