import { defineMessages } from 'react-intl';

export default defineMessages({
  level: {
    id: 'app.containers.Library.FilterModal.level',
    defaultMessage: 'Choose a Level',
  },
  words: {
    id: 'app.containers.Library.FilterModal.words',
    defaultMessage: 'Choose a Word Limit',
  },
  topic: {
    id: 'app.containers.Library.FilterModal.topic',
    defaultMessage: 'Choose a Topic',
  },
});
