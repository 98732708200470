import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import {
  reducer as bookReducer,
  sliceKey as bookSliceKey,
} from 'data/book/slice';
import {
  reducer as filterReducer,
  sliceKey as filterSliceKey,
} from 'data/filter/slice';
import {
  reducer as languageReducer,
  sliceKey as languageSliceKey,
} from 'data/language/slice';

import { filterSaga } from 'data/filter/saga';

function createReducer(asyncReducers = {}) {
  const rootReducer = combineReducers({
    [bookSliceKey]: bookReducer,
    [filterSliceKey]: filterReducer,
    [languageSliceKey]: languageReducer,
    ...asyncReducers,
  });

  return rootReducer;
}

export default function configureStore() {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const middlewares = [sagaMiddleware];
  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    createReducer(),
    {},
    composeEnhancers(...enhancers),
  );

  store.runSaga = sagaMiddleware.run;

  store.runSaga(filterSaga);

  return store;
}
