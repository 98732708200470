import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as filterActions } from 'data/filter/slice';
import { selectSearch } from 'data/filter/selectors';

import { Icon } from 'components/atoms/Icon';
import { Bar, Search } from './wrappers';

export const SearchBar = () => {
  const search = useSelector(selectSearch);
  const dispatch = useDispatch();

  return (
    <Bar>
      <Search
        type="search"
        value={search}
        onChange={e => dispatch(filterActions.applySearch(e.target.value))}
      />
      <Icon variant="search" />
    </Bar>
  );
};
