import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Button } from 'components/atoms/Button';
import { Typography } from 'components/atoms/Typography';
import { Icon } from 'components/atoms/Icon';
import Background from 'images/footer-bg.png';
import {
  Wrapper,
  BackgroundWrapper,
  ColumnWrapper,
  LogoWrapper,
} from './wrappers';
import messages from './messages';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <BackgroundWrapper style={{ backgroundImage: `url(${Background})` }} />
      <ColumnWrapper width="5%" />
      <ColumnWrapper width="42%">
        <LogoWrapper>
          <Button
            variant="icon-lg"
            iconLeft="reading-room"
            label=""
            onClick={() => navigate('/')}
          />
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.logo} />}
            onClick={() => navigate('/')}
          />
        </LogoWrapper>
        <Typography
          variant="text-md"
          as="p"
          label={<FormattedMessage {...messages.tagline} />}
        />
        <Typography
          variant="text-md"
          as="p"
          label={<FormattedMessage {...messages.copyright} />}
        />
      </ColumnWrapper>
      <ColumnWrapper width="5%">
        <Icon variant="location" />
      </ColumnWrapper>
      <ColumnWrapper width="16%">
        <Typography
          variant="text-md"
          as="p"
          label={<FormattedMessage {...messages.address1} />}
        />
        <Typography
          variant="text-md"
          as="p"
          label={<FormattedMessage {...messages.address2} />}
        />

        <Typography
          variant="text-md"
          as="p"
          label={<FormattedMessage {...messages.address3} />}
        />
      </ColumnWrapper>
      <ColumnWrapper width="5%">
        <Icon variant="email-white" />
      </ColumnWrapper>
      <ColumnWrapper width="22%">
        <Typography
          variant="text-md"
          as="p"
          label={<FormattedMessage {...messages.email} />}
        />
      </ColumnWrapper>
      <ColumnWrapper width="5%" />
    </Wrapper>
  );
};

export default Footer;
