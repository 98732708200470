import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${p => (p.columns ? '50%' : '100%')};
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 2px 0px;

  > svg {
    margin: 4px 8px 0 0;
    min-width: 12px;
    transform: scale(1);
  }
`;
