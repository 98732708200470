/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Typography } from 'components/atoms/Typography';
import { BaseModalDownload } from 'components/molecules/Modal';
import { FilterOptions } from 'components/molecules/FilterOptions';
import { ContentWrapper, ButtonWrapper, FiltersWrapper } from './wrappers';
import { filterOptions } from './constants';
import { FiltersBar } from 'components/organisms/FiltersBar';
import { DownloadBar } from 'components/organisms/DownloadBar';
import { Button } from 'components/atoms/Button';
import messages from 'containers/Home/messages'
import { Divider } from 'containers/Home/wrappers';

const DownloadModal = ({ isOpen, onClose, filter, isMobile, setIsMobileMenuOpen, confirmSelection, updateSelectAll, ...props }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const isDownload = true;
  const centered = true;
  //<div id="mydiv" style={{ textAlign: 'left' }}>Choose Categories for Download</div>
//<FormattedMessage {...messages.downloadHeader} />
  return (
  <BaseModalDownload {...{ isOpen, onClose, ...props }}>
    <ContentWrapper>
    <div id="mydiv">
    <FormattedMessage {...messages.downloadHeader} />
    </div>
        <DownloadBar {...{ isMobile, setIsMobileMenuOpen }} />
        <div id="theirdiv">
        <div id="ourdiv">
            <input type="checkbox" id="selectAll" onClick={updateSelectAll} /> Select All
        </div>
        <div id="yourdiv">
       <Button
          variant="download"
          label={<FormattedMessage {...messages.download} />}
          onClick={confirmSelection}
      />
      </div>
      </div>
    </ContentWrapper>
  </BaseModalDownload>
);
};
DownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
};

export default DownloadModal;
