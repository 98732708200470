import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Typography } from 'components/atoms/Typography';
import { Button } from 'components/atoms/Button';
import { BaseModal } from 'components/molecules/Modal';
import { ContentWrapper, ButtonsWrapper } from './wrappers';
import messages from '../messages';

const LangModal = ({ isOpen, onClose, changeLocale, ...props }) => (
  <BaseModal {...{ isOpen, onClose, ...props }}>
    <ContentWrapper>
      <Typography
        label={<FormattedMessage {...messages.chooseLanguage} />}
        variant="h2"
      />
      <ButtonsWrapper>
        <Button
          variant="icon-lg"
          iconLeft="en-UK"
          onClick={() => {
            onClose();
            setTimeout(() => changeLocale('en-UK'), 150);
          }}
          label=""
        />
        <Button
          variant="icon-lg"
          iconLeft="fr-FR"
          onClick={() => {
            onClose();
            setTimeout(() => changeLocale('fr-FR'), 150);
          }}
          label=""
        />
        <Button
          variant="icon-lg"
          iconLeft="cr-HT"
          onClick={() => {
            onClose();
            setTimeout(() => changeLocale('cr-HT'), 150);
          }}
          label=""
        />
        <Button
          variant="icon-lg"
          iconLeft="es-ES"
          onClick={() => {
            onClose();
            setTimeout(() => changeLocale('es-ES'), 150);
          }}
          label=""
        />
      </ButtonsWrapper>
    </ContentWrapper>
  </BaseModal>
);

LangModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  changeLocale: PropTypes.func.isRequired,
};

export default LangModal;
