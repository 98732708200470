import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  openBook: {
    code: '',
    title: '',
    author: '',
    level: '',
    topic: '',
    total_words: 0,
    type: '',
    pdf: '',
    png: '',
  },
};

const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    setOpenBook(state, action) {
      state.openBook = action.payload;
    },

    // reset store
    resetStore: () => ({
      ...initialState,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = bookSlice;
