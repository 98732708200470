import styled from 'styled-components';

export const Wrapper = styled.nav`
  background: ${p => (p.deep ? p.theme.color.white100 : 'none')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: 80px;
  position: fixed;
  top: 0;
  transition: all 200ms ease;
  z-index: 100;

  @media ${p => p.theme.device.desktop} {
    min-height: 120px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media ${p => p.theme.device.desktop} {
    max-width: 1760px;
    margin: 0 auto;
  }
`;

export const RightWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  right: 44px;
  height: 100%;

  > :first-child {
    margin-right: 32px;
  }

  @media (max-width: 569px) {
    > :first-child {
      display: none;
    }
  }

  @media (max-width: 420px) {
    right: 0px;
  }

`;

export const LangButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  position: absolute;
  left: 44px;
  top: 0;

  > :first-child {
    margin-right: 16px;
  }

  @media (max-width: 420px) {
    left: 0px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > * {
    margin-right: 24px;
  }

  @media (max-width: 768px) { // Adjust the max-width value based on your needs
  position: relative;
  z-index: 1000;
    margin-top: 100px;
    /*margin-left: 69px;*/
    flex-direction: row; // Ensure the links stay in a horizontal row
    align-items: center;
    justify-content: center;

    > * {
      margin-right: 24px;
    }
  }
`;
