import styled, { css } from 'styled-components';

const getTextWeight = weight => {
  switch (weight) {
    case 'bold':
      return 700;
    case 'medium':
      return 500;
    case 'normal':
    default:
      return 400;
  }
};

export const fontColors = css`
  color: ${p => p.theme.color[p.fontColor] || p.fontColor};
`;

export const h1 = css`
  font-family: ${p => p.theme.font.sans};
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  overflow-wrap: break-word; /* Ensures long words are broken and wrapped */
  white-space: normal; /* Allows text to wrap normally */

  @media ${p => p.theme.device.desktop} {
    font-size: 54px;
    line-height: 62px;
  }
`;

export const h2 = css`
  font-family: ${p => p.theme.font.sans};
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  overflow-wrap: break-word; /* Ensures long words are broken and wrapped */
  white-space: normal; /* Allows text to wrap normally */

  @media ${p => p.theme.device.desktop} {
    font-size: 36px;
    line-height: 48px;
  }
`;

export const h3 = css`
  font-family: ${p => p.theme.font.sans};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${p => p.theme.color.blue500};
  overflow-wrap: break-word; /* Ensures long words are broken and wrapped */
  white-space: normal; /* Allows text to wrap normally */

  @media ${p => p.theme.device.desktop} {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const h4 = css`
  font-family: ${p => p.theme.font.caps};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  overflow-wrap: break-word; /* Ensures long words are broken and wrapped */
  white-space: normal; /* Allows text to wrap normally */

  @media ${p => p.theme.device.desktop} {
    font-size: 15px;
    line-height: 21px;
  }
`;

export const textMd = css`
  font-family: ${p => p.theme.font.sans};
  font-weight: ${p => getTextWeight(p.weight)};
  font-size: 14px;
  line-height: 18px;

  @media ${p => p.theme.device.desktop} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const textLg = css`
  font-family: ${p => p.theme.font.sans};
  font-weight: ${p => getTextWeight(p.weight)};
  font-size: 16px;
  line-height: 20px;

  @media ${p => p.theme.device.desktop} {
    font-size: 18px;
    line-height: 32px;
  }
`;

export const textSm = css`
  font-family: ${p => p.theme.font.sans};
  font-weight: ${p => getTextWeight(p.weight)};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;

  @media ${p => p.theme.device.desktop} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const textXs = css`
  font-family: ${p => p.theme.font.sans};
  font-weight: ${p => getTextWeight(p.weight)};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
`;

export const Wrapper = styled.div`
  ${p => !!p.fontColor && fontColors}

  /* Variants */
  ${p => p.variant === 'h1' && h1}
  ${p => p.variant === 'h2' && h2}
  ${p => p.variant === 'h3' && h3}
  ${p => p.variant === 'h4' && h4}
  ${p => p.variant === 'text-md' && textMd}
  ${p => p.variant === 'text-lg' && textLg}
  ${p => p.variant === 'text-sm' && textSm}
  ${p => p.variant === 'text-xs' && textXs}
`;
