import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from 'components/atoms/Icon';
import { Typography } from 'components/atoms//Typography';
import { Wrapper, Round } from './wrappers';
import messages from './messages';

const CaptionGraphic = ({ icon, color, message }) => (
  <Wrapper key={message}>
    <Round {...{ color }}>
      <Icon variant={icon} />
    </Round>
    {!!message && (
      <Typography
        variant="text-sm"
        as="p"
        label={<FormattedMessage {...messages[message]} />}
      />
    )}
  </Wrapper>
);

CaptionGraphic.defaultProps = {
  message: '',
};

CaptionGraphic.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  message: PropTypes.string,
};

export default CaptionGraphic;
