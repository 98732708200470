import { defineMessages } from 'react-intl';

export default defineMessages({
  words: {
    id: 'app.components.molecules.FilterOptions.words',
    defaultMessage: `Up to {number} words`,
  },
  wordless: {
    id: 'app.components.molecules.FilterOptions.wordless',
    defaultMessage: `Wordless`,
  },
  highWords: {
    id: 'app.components.molecules.FilterOptions.high-words',
    defaultMessage: `Over 2,500 words`,
  },
  pages: {
    id: 'app.components.molecules.FilterOptions.pages',
    defaultMessage: `Up to {number} pages`,
  },
  english: {
    id: 'app.components.molecules.FilterOptions.english',
    defaultMessage: 'English',
  },
  creole: {
    id: 'app.components.molecules.FilterOptions.creole',
    defaultMessage: 'Haitian Creole',
  },
  french: {
    id: 'app.components.molecules.FilterOptions.french',
    defaultMessage: 'French',
  },
  spanish: {
    id: 'app.components.molecules.FilterOptions.spanish',
    defaultMessage: 'Spanish',
  },

  storyweaver: {
    id: 'app.components.molecules.FilterOptions.source-storyweaver',
    defaultMessage: 'Storyweaver',
  },
  africanStorybook: {
    id: 'app.components.molecules.FilterOptions.source-africanStorybook',
    defaultMessage: 'African Storybook',
  },
  davidRevoy: {
    id: 'app.components.molecules.FilterOptions.source-davidRevoy',
    defaultMessage: 'David Revoy',
  },
  bookdash: {
    id: 'app.components.molecules.FilterOptions.source-bookdash',
    defaultMessage: 'Bookdash',
  },
  mustardSeed: {
    id: 'app.components.molecules.FilterOptions.source-mustardSeed',
    defaultMessage: 'Mustard Seed',
  },
  bibleForChildren: {
    id: 'app.components.molecules.FilterOptions.source-bibleForChildren',
    defaultMessage: 'Bible For Children',
  },
  unesco: {
    id: 'app.components.molecules.FilterOptions.source-unesco',
    defaultMessage: 'UNESCO',
  },
  storybooksCanada: {
    id: 'app.components.molecules.FilterOptions.source-storybooksCanada',
    defaultMessage: 'Storybooks Canada',
  },
  bookbot: {
    id: 'app.components.molecules.FilterOptions.source-bookbot',
    defaultMessage: 'Bookbot',
  },
  bookSprint: {
    id: 'app.components.molecules.FilterOptions.source-bookSprint',
    defaultMessage: 'Book Sprint',
  },

  fiction: {
    id: 'app.components.molecules.FilterOptions.fiction',
    defaultMessage: 'Fiction',
  },
  nonFiction: {
    id: 'app.components.molecules.FilterOptions.nonFiction',
    defaultMessage: 'Non-fiction',
  },
  graphicNovels: {
    id: 'app.components.molecules.FilterOptions.graphicNovels',
    defaultMessage: 'Graphic Novels',
  },
  bookSeries: {
    id: 'app.components.molecules.FilterOptions.bookSeries',
    defaultMessage: 'Book Series',
  },
  decodableTexts: {
    id: 'app.components.molecules.FilterOptions.decodableTexts',
    defaultMessage: 'Decodable Texts',
  },

  beginning: {
    id: 'app.components.molecules.FilterOptions.level-beginning',
    defaultMessage: 'Beginning reader',
  },
  early: {
    id: 'app.components.molecules.FilterOptions.level-early',
    defaultMessage: 'Early elementary',
  },
  middle: {
    id: 'app.components.molecules.FilterOptions.level-middle',
    defaultMessage: 'Middle elementary',
  },
  late: {
    id: 'app.components.molecules.FilterOptions.level-late',
    defaultMessage: 'Late elementary',
  },
  earlyMiddle: {
    id: 'app.components.molecules.FilterOptions.level-earlyMiddle',
    defaultMessage: 'Early middle school',
  },
  lateMiddle: {
    id: 'app.components.molecules.FilterOptions.level-lateMiddle',
    defaultMessage: 'Late middle school',
  },
  high: {
    id: 'app.components.molecules.FilterOptions.level-high',
    defaultMessage: 'High school',
  },

  adventure: {
    id: 'app.components.molecules.FilterOptions.topic-adventure',
    defaultMessage: 'Adventure',
  },
  fantasy: {
    id: 'app.components.molecules.FilterOptions.topic-fantasy',
    defaultMessage: 'Fantasy',
  },
  mystery: {
    id: 'app.components.molecules.FilterOptions.topic-mystery',
    defaultMessage: 'Mystery',
  },
  cats: {
    id: 'app.components.molecules.FilterOptions.topic-cats',
    defaultMessage: 'Cats',
  },
  dogs: {
    id: 'app.components.molecules.FilterOptions.topic-dogs',
    defaultMessage: 'Dogs',
  },
  insects: {
    id: 'app.components.molecules.FilterOptions.topic-insects',
    defaultMessage: 'Insects',
  },
  environment: {
    id: 'app.components.molecules.FilterOptions.topic-environment',
    defaultMessage: 'Environment',
  },
  otherFiction: {
    id: 'app.components.molecules.FilterOptions.topic-otherFiction',
    defaultMessage: 'Other Fiction',
  },
  animals: {
    id: 'app.components.molecules.FilterOptions.topic-animals',
    defaultMessage: 'Animals',
  },
  birds: {
    id: 'app.components.molecules.FilterOptions.topic-birds',
    defaultMessage: 'Birds',
  },
  fish: {
    id: 'app.components.molecules.FilterOptions.topic-fish',
    defaultMessage: 'Fish',
  },
  nature: {
    id: 'app.components.molecules.FilterOptions.topic-nature',
    defaultMessage: 'Nature',
  },
  water: {
    id: 'app.components.molecules.FilterOptions.topic-water',
    defaultMessage: 'Water',
  },
  culture: {
    id: 'app.components.molecules.FilterOptions.topic-culture',
    defaultMessage: 'Culture',
  },
  family: {
    id: 'app.components.molecules.FilterOptions.topic-family',
    defaultMessage: 'Family',
  },
  feelings: {
    id: 'app.components.molecules.FilterOptions.topic-feelings',
    defaultMessage: 'Feelings',
  },
  food: {
    id: 'app.components.molecules.FilterOptions.topic-food',
    defaultMessage: 'Food',
  },
  friends: {
    id: 'app.components.molecules.FilterOptions.topic-friends',
    defaultMessage: 'Friends',
  },
  school: {
    id: 'app.components.molecules.FilterOptions.topic-school',
    defaultMessage: 'School',
  },
  sports: {
    id: 'app.components.molecules.FilterOptions.topic-sports',
    defaultMessage: 'Sports',
  },
  biography: {
    id: 'app.components.molecules.FilterOptions.topic-biography',
    defaultMessage: 'Biography',
  },
  history: {
    id: 'app.components.molecules.FilterOptions.topic-history',
    defaultMessage: 'History',
  },
  math: {
    id: 'app.components.molecules.FilterOptions.topic-math',
    defaultMessage: 'Math',
  },
  religion: {
    id: 'app.components.molecules.FilterOptions.topic-religion',
    defaultMessage: 'Religion',
  },
  science: {
    id: 'app.components.molecules.FilterOptions.topic-science',
    defaultMessage: 'Science',
  },
  otherNonfiction: {
    id: 'app.components.molecules.FilterOptions.topic-otherNonfiction',
    defaultMessage: 'Other Non-fiction',
  },
});
