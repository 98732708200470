import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const PageWrapper = styled.div`
  transition: all 300ms ease;
  > div > canvas {
    width: auto !important;
    max-width: 108vw !important;
    height: auto !important;
    max-height: 104vh !important;
    transition: all 300ms ease;
    margin: 0 auto;
    margin-top: -30px;
    z-index: 0;
  }
`;

export const LoadingWrapper = styled.div`
  height: 100dvh;
  width: 100dvw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderWrapper = styled.nav`
  height: 40px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;

  > button {
    margin: 0 16px;
  }
`;
