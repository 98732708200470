import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectLocale } from 'data/language/selectors';
import { IntlProvider } from 'react-intl';

import French from 'lang/fr-FR.json';
import Spanish from 'lang/es-ES.json';
import English from 'lang/en-UK.json';
import Creole from 'lang/cr-HT.json';

const LanguageProvider = ({ children }) => {
  const locale = useSelector(selectLocale);
  return (
    <IntlProvider
      locale={locale}
      messages={
        locale === 'en-UK'
          ? English
          : locale === 'fr-FR'
          ? French
          : locale === 'es-ES'
          ? Spanish
          : Creole
      }
      defaultLocale="en-UK"
    >
      {children}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
