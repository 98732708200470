export const size = {
  tablet: '768px',
  desktop: '1370px',
  phone: '420px',
};

export const device = {
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
  phone: `(min-width: ${size.phone})`,
};
