import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  bookQueue: [],
  modal: '',
  language: [],
  source: [],
  type: [],
  level: [],
  total_words: null,
  topic: [],
  search: '',
  library: [],
  selectAll: false,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    applyModal(state, action) {
      state.modal = action.payload;
    },
    applyLanguage(state, action) {
      if (state.language.includes(action.payload)) {
        state.language = [...state.language.filter(i => i !== action.payload)];
      } else {
        state.language = [...state.language, action.payload];
      }
    },
    applySource(state, action) {
      if (state.source.includes(action.payload)) {
        state.source = [...state.source.filter(i => i !== action.payload)];
      } else {
        state.source = [...state.source, action.payload];
      }
    },
    applyType(state, action) {
      if (state.type.includes(action.payload)) {
        state.type = [...state.type.filter(i => i !== action.payload)];
      } else {
        state.type = [...state.type, action.payload];
      }
    },
    applyLevel(state, action) {
      if (state.level.includes(action.payload)) {
        state.level = [...state.level.filter(i => i !== action.payload)];
      } else {
        state.level = [...state.level, action.payload];
      }
    },
    applyWords(state, action) {
      state.total_words = action.payload;
    },
    applyTopic(state, action) {
      if (state.topic.includes(action.payload)) {
        state.topic = [...state.topic.filter(i => i !== action.payload)];
      } else {
        state.topic = [...state.topic, action.payload];
      }
    },
    applySearch(state, action) {
      state.search = action.payload;
    },
    applySelectAll(state, action) {
      state.selectall = action.payload;
    },
    setLibrary(state, action) {
      state.library = action.payload;
      state.bookQueue = action.payload;
    },
    clear(state, action) {
      state.language = [];
      state.type = [];
      state.source = [];
      state.level = [];
      state.total_words = null;
      state.topic = [];
      state.search = '';
    },
    fetchBooksStart(state) {
      // Possibly set some loading state
    },
    setBookQueue(state, action) {
      state.bookQueue = action.payload;
    },

    // reset store
    resetStore: () => ({
      ...initialState,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = filterSlice;

/* old implementation of updateBooks
/* state.library.filter(book => {
    if (
      state.language[0] &&
      !state.language.includes(lookupFilter[book.language])
    )
      return null;
    if (
      state.source[0] &&
      !state.source.includes(lookupFilter[book.source])
    )
      return null;
    if (state.type[0] && !state.type.includes(lookupFilter[book.type]))
      return null;
    if (state.level[0] && !state.level.includes(lookupFilter[book.level]))
      return null;
    if (
      ((state.total_words || state.total_words === 0) &&
        book.total_words > state.total_words) ||
      (state.total_words === '2500+' && book.total_words < 2500)
    )
      return null;
    if (
      state.topic[0] &&
      state.topic
        .map(t =>
          [
            lookupFilter[book['topic 1']],
            lookupFilter[book['topic 2']],
          ].includes(t),
        )
        .filter(f => !!f).length === 0
    )
      return null;
    if (
      state.search &&
      !(
        book.title.toLowerCase().includes(state.search.toLowerCase()) ||
        book.author.toLowerCase().includes(state.search.toLowerCase())
      )
    )
      return null;
    return book;
  });
*/
