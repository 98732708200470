import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  width: 48px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const OfflineMessage = styled.div`
  flex: auto;
  align-items: center;
  justify-content: center;
`;
