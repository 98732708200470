import React from 'react';
import PropTypes from 'prop-types';

import BookViewer from 'containers/BookViewer';
import { FullModal } from 'components/molecules/Modal';

const BookModal = ({ isOpen, onClose, openBook, ...props }) => (
  <FullModal {...{ isOpen, onClose, ...props }}>
    <BookViewer {...{ openBook }} />
  </FullModal>
);

BookModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openBook: PropTypes.shape({
    code: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
    level: PropTypes.string,
    topic: PropTypes.string,
    total_words: PropTypes.number,
    type: PropTypes.string,
    pdf: PropTypes.string,
    png: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // eslint-disable-line
  }).isRequired,
};

export default BookModal;
