import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${p => p.theme.color.blue100};
  width: 100%;
  height: ${p => `calc(100vh - ${p.marginTop})`};
  margin-top: ${p => p.marginTop};
  padding: 16px 8px 0px;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* hide scrollbar for chrome, safari and opera */
  ::-webkit-scrollbar {
    display: none;
  }

  @media ${p => p.theme.device.tablet} {
    padding: 16px 24px 0px;
  }

  @media ${p => p.theme.device.desktop} {
    padding: 0px 44px;
  }
`;

export const LangButtonWrapper = styled.label`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FiltersWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 0;

  @media ${p => p.theme.device.tablet} {
    width: 300px;
  }

  @media ${p => p.theme.device.desktop} {
    width: 380px;
  }
`;

export const BooksWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
`;
