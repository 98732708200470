/* eslint-disable */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getFilteredBooks } from 'data/database';
import { actions } from './slice';
import { getPWADisplayMode } from 'shared/functions/utils';

const selectFilters = state => state.filter;

export function* fetchBooksSaga() {
  try {
    console.log('fetchBooksSaga called');
    const browser = getPWADisplayMode() === 'browser';
    yield put(actions.setBookQueue([]));
    const filters = yield select(selectFilters);
    const books = yield call(getFilteredBooks, filters, 0, 10, browser);
    yield put(actions.setBookQueue(books));
  } catch (error) {
    console.error('Failed to fetch books', error);
  }
}

export function* filterSaga() {
  yield all([
    takeLatest(actions.applyLanguage.type, fetchBooksSaga),
    takeLatest(actions.applySource.type, fetchBooksSaga),
    takeLatest(actions.applyType.type, fetchBooksSaga),
    takeLatest(actions.applyLevel.type, fetchBooksSaga),
    takeLatest(actions.applyWords.type, fetchBooksSaga),
    takeLatest(actions.applyTopic.type, fetchBooksSaga),
    takeLatest(actions.applySearch.type, fetchBooksSaga),
    takeLatest(actions.clear.type, fetchBooksSaga),
  ]);
}
