import styled from 'styled-components';

export const Wrapper = styled.div`
  border: none;
  margin: 0 auto;
  position: relative;
  display: block;
  padding: 8px;
  height: auto;
  width: 100%;
`;

export const Option = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 18px;
  color: ${p => p.theme.color.black100};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  &:hover {
    > :first-child {
      background-color: ${p => p.theme.color.red100};
    }
  }
`;

export const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 24px;
  border: 1px solid ${p => p.theme.color.red200};
  border-radius: 2px;
  position: relative;
  display: flex;
  z-index: 1;
`;

export const Checkmark = styled.span`
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 7px);
  z-index: 2;
`;
