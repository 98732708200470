import { defineMessages } from 'react-intl';

export default defineMessages({
  navHeader: {
    id: 'app.containers.Nav.header',
    defaultMessage: 'The Reading Room',
  },
  donate: {
    id: 'app.containers.Nav.donate',
    defaultMessage: 'Donate',
  },
  chooseLanguage: {
    id: 'app.containers.Nav.LangModal.header',
    defaultMessage: 'Choose a Language',
  },
  home: {
    id: 'app.containers.Nav.home',
    defaultMessage: 'Home',
  },
  library: {
    id: 'app.containers.Nav.library',
    defaultMessage: 'Library App',
  },
  about: {
    id: 'app.containers.Nav.about',
    defaultMessage: 'About Us',
  },
  help: {
    id: 'app.containers.Nav.help',
    defaultMessage: 'How to Help',
  },
});
