import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${p => p.theme.color.white100};
  border: none;
  border-radius: 25px;
  box-shadow: ${p => p.theme.shadow.md};
  cursor: pointer;
  width: 94%;
  height: 94%;
  margin: 0 auto;
  position: relative;
  transition: all 300ms ease;
  text-align: left;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* hide scrollbar for chrome, safari and opera */
  ::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    box-shadow: ${p => p.theme.shadow.xl};
  }
`;

export const TitleWrapper = styled.div`
  display: block;
  background-color: ${p => p.theme.color.white100};
  border-radius: 25px;
  padding: 0px 16px;
  text-align: left;
  height: auto;

  > :first-child {
    margin-bottom: 8px;
  }
`;

export const TagWrapper = styled.div`
  display: inline-block;
  background-color: ${p => p.theme.color.red100};
  border-radius: 25px;
  color: ${p => p.theme.color.red500};
  padding: 6px 10px;
  text-align: left;
  text-transform: uppercase;
  height: auto;
  width: auto;
  margin: 10px;

  @media ${p => p.theme.device.desktop} {
    margin: 12px;
    padding: 8px 16px;
  }
`;

export const PictureWrapper = styled.div`
  background-image: ${p => `url('data:image/webp;base64,${p.png}')`};
  background-size: cover;
  background-position: center center;
  border-radius: 25px 25px 0 0;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  text-align: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 25px;
  padding: 8px 16px;
  height: 60px;
  width: 100%;
  text-align: left;
  position: absolute;
  bottom: 8px;
  right: 0;

  > :first-child {
    margin-bottom: 8px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;

  > :first-child {
    margin-right: 8px;
  }
`;

export const Dot = styled.div`
  background-color: ${p => p.theme.color[p.color]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 4px;

  > img {
    width: calc(100% - 2px);
    height: auto;
  }
`;
