import CarouselLookup from 'images/carousel-lookup.png';
import CarouselGroup from 'images/chantal.jpeg';
import CarouselFriends from 'images/carousel-friends.png';
import CarouselRead from 'images/carousel-read.png';

export const images = [
  {
    title: 'look-up',
    src: CarouselLookup,
  },
  {
    title: 'story-time',
    src: CarouselGroup,
  },
  {
    title: 'friends',
    src: CarouselFriends,
  },
  {
    title: 'reading',
    src: CarouselRead,
  },
];

export const booksMessages = [
  'frenchBooks',
  'readAloud',
  'songsLyrics',
  'creoleBooks',
  'access',
];

export const worldMessages = [
  'africa',
  'middleEast',
  'cambodia',
  'india',
  'vietnam',
  'france',
  'canada',
  'unitedStates',
];

export const haitiMessages = ['materials', 'downloaded', 'circumstances'];

export const deviceMessages = ['tablets', 'laptops', 'desktops'];

export const tabletMessages = [
  'internet',
  'minutes',
  'anywhere',
  'annual',
  'update',
];

export const technologyMessages = [
  'relies',
  'explosion',
  'canDownload',
  'cheaper',
  'advances',
];

export const thriveMessages = [
  { icon: 'openbook', color: 'blue500', msg: 'accessTo' },
  { icon: 'pencil', color: 'red500', msg: 'timeTo' },
  { icon: 'grad', color: 'blue200', msg: 'teachers' },
  { icon: 'classroom', color: 'blue200', msg: 'instruction' },
  { icon: 'rocket', color: 'blue500', msg: 'literacy' },
  { icon: 'speech-white', color: 'red500', msg: 'talk' },
];

export const meetMessages = [
  'working',
  'experience',
  'flash',
  'camps',
  'training',
];

export const filterOptions = {
  level: [1, 2, 3, 4],
  words: [50, 100, 150, 200],
  topic: ['football', 'animals', 'water', 'food', 'friends', 'africa'],
};

