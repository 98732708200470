import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from 'components/atoms/Icon';
import { Typography } from 'components/atoms//Typography';
import { Wrapper } from './wrappers';
import messages from './messages';

const CheckItem = ({ icon, message, columns }) => (
  <Wrapper key={message} {...{ columns }}>
    {!!icon && <Icon variant={icon} />}
    {!!message && (
      <Typography
        variant="text-md"
        as="p"
        label={<FormattedMessage {...messages[message]} />}
      />
    )}
  </Wrapper>
);

CheckItem.defaultProps = {
  icon: null,
  columns: false,
};

CheckItem.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  columns: PropTypes.bool,
};

export default CheckItem;
