import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from 'components/atoms/Typography';
import CheckItem from 'components/atoms/CheckItem';
import Carousel from 'components/molecules/Carousel';
import Row from 'containers/Row';
import Footer from 'containers/Footer';

import { meetMessages } from 'containers/Home/constants';
import HeaderBG from 'images/header-bg.png';
import WhoWeAre from 'images/who-we-are.png';
import Wicks from 'images/elizabeth_and_paul.jpeg';
import Chantal from 'images/chantal.png';
import Paul from 'images/paul.png';
import Michael from 'images/michael.jpg';
import Kyle from 'images/kyle.jpg';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { images } from './constants';

import {
  BackgroundWrapper,
  ColumnWrapper,
  HeaderWrapper,
  IllustrationWrapper,
  RowWrapper,
  Wrapper,
} from '../wrappers';
import messages from './messages';

const Staff = () => {
  const isTablet = useMediaQuery('(max-width: 1370px)');
  return (
    <Wrapper>
      <HeaderWrapper src={HeaderBG}>
        <Typography
          variant="h1"
          as="h1"
          label={<FormattedMessage {...messages.header} />}
        />
      </HeaderWrapper>
      <RowWrapper>
        <ColumnWrapper padding="0px">
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.news} />}
          />
          <a
            href="https://apnews.com/article/4e51047ff94442b7af0adab88a77b372"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              variant="h4"
              as="h4"
              label={<FormattedMessage {...messages.newsAP} />}
            />
          </a>
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper>
        <IllustrationWrapper>
          <img alt="who we are" src={WhoWeAre} />
        </IllustrationWrapper>
        <ColumnWrapper>
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.who} />}
          />
          <CheckItem message="nonprofit" />
          {meetMessages.map(msg => (
            <CheckItem key={msg} icon="check-red" message={msg} />
          ))}
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper>
        <BackgroundWrapper color="blue100" />
        <Row
          imageRight={Chantal}
          height={isTablet ? '482px' : '452px'}
          padding="24px 60px"
        >
          <Typography
            variant="h4"
            as="h4"
            label={<FormattedMessage {...messages.director} />}
          />
          <Typography variant="h2" as="h2" label="Chantal Coutard" />
          <Typography
            variant="p"
            as="p"
            label={<FormattedMessage {...messages.chantal} />}
          />
        </Row>
      </RowWrapper>
      <RowWrapper>
        <Row
          imageLeft={Wicks}
          height={isTablet ? '482px' : '452px'}
          padding="24px 60px"
        >
          <Typography
            variant="h4"
            as="h4"
            label={<FormattedMessage {...messages.cofounderF} />}
          />
          <Typography variant="h2" as="h2" label="Elizabeth Wicks" />
          <Typography
            variant="p"
            as="p"
            label={<FormattedMessage {...messages.elizabeth} />}
          />
        </Row>
      </RowWrapper>
      <RowWrapper>
        <BackgroundWrapper color="blue100" />
        <Row
          imageRight={Paul}
          height={isTablet ? '360px' : '432px'}
          padding="24px 60px"
        >
          <Typography
            variant="h4"
            as="h4"
            label={<FormattedMessage {...messages.cofounderM} />}
          />
          <Typography variant="h2" as="h2" label="Paul Wicks" />
          <Typography
            variant="p"
            as="p"
            label={<FormattedMessage {...messages.paul} />}
          />
        </Row>
      </RowWrapper>
      <RowWrapper>
        <Row
          imageLeft={Michael}
          height={isTablet ? '482px' : '452px'}
          maxWidth="450px"
          padding="24px 60px"
          imageMargin
        >
          <Typography
            variant="h4"
            as="h4"
            label={<FormattedMessage {...messages.backend} />}
          />
          <Typography variant="h2" as="h2" label="Michael Wicks" />
          <Typography
            variant="p"
            as="p"
            label={<FormattedMessage {...messages.michael} />}
          />
        </Row>
      </RowWrapper>
      <RowWrapper>
        <BackgroundWrapper color="blue100" />
        <Row
          imageRight={Kyle}
          height={isTablet ? '482px' : '452px'}
          maxWidth="450px"
          padding="24px 60px"
          imageMargin
        >
          <Typography
            variant="h4"
            as="h4"
            label={<FormattedMessage {...messages.frontend} />}
          />
          <Typography variant="h2" as="h2" label="Kyle Forgeron" />
          <Typography
            variant="p"
            as="p"
            label={<FormattedMessage {...messages.kyle} />}
          />
        </Row>
      </RowWrapper>
      <RowWrapper margin="140px 44px 0px">
        <Carousel variant="large" {...{ images }} />
      </RowWrapper>
      <Footer />
    </Wrapper>
  );
};

export default Staff;
