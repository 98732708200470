import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 16px 16px 16px 0px;

  p {
    width: 150px;
    margin: 16px auto;
    text-align: center;
  }
`;

export const Round = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${p => p.theme.color[p.color]};
`;
