import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from 'components/atoms/Typography';
import { Icon } from 'components/atoms/Icon';
import { Button } from 'components/atoms/Button';
import CaptionGraphic from 'components/atoms/CaptionGraphic';
import CheckItem from 'components/atoms/CheckItem';
import Row from 'containers/Row';
import Footer from 'containers/Footer';

import HeaderBG from 'images/header-bg.png';
import HelpFriends from 'images/help-friends.png';
import HelpLearn from 'images/help-learn.png';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

import { Divider } from 'containers/Home/wrappers';
import {
  BackgroundWrapper,
  CenteredIcon,
  CenteredText,
  ColumnWrapper,
  DividerVertical,
  HeaderWrapper,
  RowWrapper,
  Wrapper,
} from '../wrappers';
import { donationMessages } from './constants';
import messages from './messages';

const Staff = () => {
  const isTablet = useMediaQuery('(max-width: 1370px)');
  return (
    <Wrapper>
      <HeaderWrapper src={HeaderBG}>
        <Typography
          variant="h1"
          as="h1"
          label={<FormattedMessage {...messages.header} />}
        />
      </HeaderWrapper>
      <RowWrapper>
        <ColumnWrapper width={isTablet ? '75%' : '66.6%'}>
          <CenteredIcon bottom="44px">
            <Icon variant="goal" />
          </CenteredIcon>
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.expand} />}
          />
          <Typography
            variant="p"
            as="p"
            label={<FormattedMessage {...messages.funds} />}
          />
        </ColumnWrapper>
        <Divider color="black400" />
      </RowWrapper>
      <RowWrapper flex>
        <RowWrapper flex width="28%" margin="0px auto">
          <CaptionGraphic icon="coin" color="blue100" />
          <ColumnWrapper width="60%" padding="0" flex column>
            <Typography
              variant="h3"
              as="h3"
              label={
                <FormattedMessage {...messages.add} values={{ amount: 100 }} />
              }
            />
            <br />
            <Typography
              variant="h4"
              as="h4"
              label={
                <FormattedMessage {...messages.books} values={{ amount: 10 }} />
              }
            />
          </ColumnWrapper>
        </RowWrapper>
        <RowWrapper flex width="30%" margin="0px auto">
          <CaptionGraphic icon="coins" color="blue100" />
          <ColumnWrapper width="60%" padding="0" flex column>
            <Typography
              variant="h3"
              as="h3"
              label={
                <FormattedMessage {...messages.add} values={{ amount: 1000 }} />
              }
            />
            <br />
            <Typography
              variant="h4"
              as="h4"
              label={
                <FormattedMessage
                  {...messages.books}
                  values={{ amount: 100 }}
                />
              }
            />
          </ColumnWrapper>
        </RowWrapper>
        <RowWrapper flex width="34%" margin="0px auto">
          <CaptionGraphic icon="tablet" color="blue100" />
          <ColumnWrapper width="60%" padding="0" flex column>
            <Typography
              variant="h3"
              as="h3"
              label={<FormattedMessage {...messages.stay} />}
            />
            <br />
            <Typography
              variant="h4"
              as="h4"
              label={<FormattedMessage {...messages.permanently} />}
            />
          </ColumnWrapper>
        </RowWrapper>
      </RowWrapper>
      <RowWrapper
        padding={isTablet ? '44px 0px' : '96px 0px'}
        color="blue100"
        height={isTablet ? '360px' : '616px'}
        margin={isTablet ? '40px 44px 260px' : '100px 44px 220px'}
      >
        <ColumnWrapper width={isTablet ? '75%' : '66.6%'} padding="0px">
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.fulfillment} />}
          />
        </ColumnWrapper>
        <ColumnWrapper flex center width="1225px">
          <img
            alt="friends"
            src={HelpFriends}
            width={isTablet ? '80%' : '100%'}
          />
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper flex margin={isTablet ? '40px 44px' : '320px 44px 40px'}>
        <ColumnWrapper>
          <CenteredIcon bottom="44px">
            <Icon variant="donate-red" />
          </CenteredIcon>
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.donate} />}
          />
          <CenteredIcon>
            <Button
              variant="primary"
              iconLeft="donate-white"
              label={<FormattedMessage {...messages.donateClick} />}
              onClick={() =>
                window.open(
                  'https://www.paypal.com/donate/?hosted_button_id=KJUY73PTWCLWC',
                  '_blank',
                )
              }
            />
          </CenteredIcon>
        </ColumnWrapper>
        <DividerVertical color="black400" />
        <ColumnWrapper padding="0px">
          <CenteredIcon bottom="44px">
            <Icon variant="email-red" />
          </CenteredIcon>
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.check} />}
          />
          <ColumnWrapper width="180px" margin="0 auto">
            <CenteredText>
              <Typography
                variant="text-lg"
                as="p"
                label={<FormattedMessage {...messages.readingRoom} />}
              />
              <Typography
                variant="text-lg"
                as="p"
                label={<FormattedMessage {...messages.address1} />}
              />
              <Typography
                variant="text-lg"
                as="p"
                label={<FormattedMessage {...messages.address2} />}
              />
              <Typography
                variant="text-lg"
                as="p"
                label={<FormattedMessage {...messages.address3} />}
              />
            </CenteredText>
          </ColumnWrapper>
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper
        color="blue100"
        padding={isTablet ? '32px' : '44px'}
        margin="0px 44px"
      >
        <CenteredIcon bottom="24px">
          <Icon variant="speech-blue" />
        </CenteredIcon>
        <ColumnWrapper padding="0px">
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.questions} />}
            style={{ marginBottom: '16px' }}
          />
          <a href="mailto:readingroomhaiti@cox.net">
            <Typography variant="h5" as="h5" label="readingroomhaiti@cox.net" />
          </a>
        </ColumnWrapper>
      </RowWrapper>
      <RowWrapper>
        <BackgroundWrapper />
        <Row imageLeft={HelpLearn} height="452px" padding="24px 60px">
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.efficiently} />}
          />
          {donationMessages.map(msg => (
            <CheckItem key={msg} icon="check-red" message={msg} />
          ))}
        </Row>
      </RowWrapper>
      <Footer />
    </Wrapper>
  );
};

export default Staff;
