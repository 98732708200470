import GalleryShow from 'images/gallery-show.png';
import GalleryClass from 'images/gallery-class.png';
import GalleryRead from 'images/gallery-read.png';
import GalleryGroup from 'images/reading_group.jpeg';
import GalleryTeach from 'images/rr_bg.jpeg';

export const images = [
  {
    title: 'flash-library',
    src: GalleryClass,
  },
  {
    title: 'show-book',
    src: GalleryShow,
  },
  {
    title: 'teaching-class',
    src: GalleryTeach,
  },
  {
    title: 'child-reading',
    src: GalleryRead,
  },
  {
    title: 'reading-group',
    src: GalleryGroup,
  },
];
