import { device, size } from './devices';

const theme = {
  /* Colors */
  color: {
    /* Primary Colors */
    red100: '#D52C4D10',
    red200: '#efacae',
    red500: '#D52C4D',
    blue100: '#D7EBFF',
    blue200: '#82b2d9',
    blue300: '#3A93EC',
    blue500: '#002664',

    /* Neutral Colors */
    black100: '#1F1F1F',
    black200: '#2F2F2F',
    black300: '#E1E1E1',
    black400: '#DEDEDE',
    white100: '#FFFFFF',
    clear: '#00000000',
  },

  /* Box Shadows */
  shadow: {
    sm: '0px 1px 3px rgba(32, 32, 34, 0.1), 0px 1px 2px rgba(32, 32, 34, 0.06)',
    md: '0px 4px 8px -2px rgba(32, 32, 34, 0.1), 0px 2px 4px -2px rgba(32, 32, 34, 0.06)',
    lg: '0px 12px 16px -4px rgba(32, 32, 34, 0.1), 0px 4px 6px -2px rgba(32, 32, 34, 0.05)',
    xl: '0px 20px 24px -4px rgba(32, 32, 34, 0.1), 0px 8px 8px -4px rgba(32, 32, 34, 0.04)',
    xxl: '0px 24px 48px -12px rgba(32, 32, 34, 0.25)',
    xxxl: '0px 32px 64px -12px rgba(32, 32, 34, 0.2)',
  },

  /* Font Families */
  font: {
    sans: "'Montserrat', sans-serif",
    caps: "'Hind', sans-serif",
  },

  /* Sizes & Devices */
  size,
  device,
};

export default theme;
