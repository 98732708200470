import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  locale: 'en-UK',
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    applyLocale(state, action) {
      state.locale = action.payload;
    },

    // reset store
    resetStore: () => ({
      ...initialState,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = languageSlice;
