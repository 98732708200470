import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ReactModal from 'react-modal';

import LanguageProvider from 'containers/LanguageProvider';
import { App } from './containers/App';
import configureStore from './data/store';
import { GlobalStyle } from './shared/globalStyle';
import themePrimary from './shared/themePrimary';

const store = configureStore();
const container = document.getElementById('root');
const root = createRoot(container);
ReactModal.setAppElement(container);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(registration => {
        // console.log('SW registered: ', registration);
      })
      .catch(registrationError => {
        // console.log('SW registration failed: ', registrationError);
      });
  });
}

root.render(
  <Provider store={store}>
    <LanguageProvider defaultLocale="en">
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider theme={themePrimary}>
            <React.StrictMode>
              <App />
            </React.StrictMode>
            <GlobalStyle />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </LanguageProvider>
  </Provider>,
);
