import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: ${p => p.theme.font.sans};
    color: ${p => p.theme.color.black900};
    overflow-y: auto;

    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* hide scrollbar for chrome, safari and opera */
    ::-webkit-scrollbar {
      display: none;
    }
  }



  /* https://a11yproject.com/posts/how-to-hide-content/ */
  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  }
`;
