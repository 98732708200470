import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 60px auto;
  margin-top: ${p => p.variant === 'large' && '0px'};
  position: relative;
  transition: all 300ms ease;
  text-align: left;
  overflow-x: scroll;
  width: calc(100vw - 88px);

  @media ${p => p.theme.device.desktop} {
    margin: 100px auto;
    margin-top: ${p => p.variant === 'large' && '80px'};
  }
`;

export const PictureWrapper = styled.img`
  height: ${p => (p.variant === 'large' ? '442px' : '380px')};
  width: auto;
  margin: 0 auto;
  :not(:first-child) {
    margin-left: ${p => p.variant === 'large' && '44px'};
  }

  @media ${p => p.theme.device.desktop} {
    height: ${p => (p.variant === 'large' ? '542px' : '380px')};
  }
`;
