import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as Search } from './assets/search.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as Dash } from './assets/dash.svg';
import { ReactComponent as Ellipse } from './assets/ellipse.svg';
import { ReactComponent as EyeOpen } from './assets/eye-open.svg';
import { ReactComponent as EyeClosed } from './assets/eye-closed.svg';
import { ReactComponent as Menu } from './assets/menu.svg';
import { ReactComponent as Valid } from './assets/valid.svg';
import { ReactComponent as Invalid } from './assets/invalid.svg';
import { ReactComponent as Placeholder } from './assets/placeholder.svg';
import { ReactComponent as Warning } from './assets/warning.svg';
import { ReactComponent as Question } from './assets/question.svg';
import { ReactComponent as Down } from './assets/down.svg';
import { ReactComponent as Bookstack } from './assets/bookstack.svg';
import { ReactComponent as CalendarBlue } from './assets/calendar-blue.svg';
import { ReactComponent as CalendarRed } from './assets/calendar-red.svg';
import { ReactComponent as CheckRed } from './assets/check-red.svg';
import { ReactComponent as CheckWhite } from './assets/check-white.svg';
import { ReactComponent as Classroom } from './assets/classroom.svg';
import { ReactComponent as Coin } from './assets/coin.svg';
import { ReactComponent as Coins } from './assets/coins.svg';
import { ReactComponent as DonateRed } from './assets/donate-red.svg';
import { ReactComponent as DonateWhite } from './assets/donate-white.svg';
import { ReactComponent as EmailRed } from './assets/email-red.svg';
import { ReactComponent as EmailWhite } from './assets/email-white.svg';
import { ReactComponent as Globe } from './assets/globe.svg';
import { ReactComponent as Goal } from './assets/goal.svg';
import { ReactComponent as Grad } from './assets/grad.svg';
import { ReactComponent as Haiti } from './assets/haiti.svg';
import { ReactComponent as Location } from './assets/location.svg';
import { ReactComponent as NavLeft } from './assets/nav-left.svg';
import { ReactComponent as NavRight } from './assets/nav-right.svg';
import { ReactComponent as OpenBook } from './assets/openbook.svg';
import { ReactComponent as Pages } from './assets/pages.svg';
import { ReactComponent as SpeechBlue } from './assets/speech-blue.svg';
import { ReactComponent as SpeechWhite } from './assets/speech-white.svg';
import { ReactComponent as Tablet } from './assets/tablet.svg';
import { ReactComponent as Words } from './assets/words.svg';
import Rocket from './assets/rocket.png';
import Pencil from './assets/pencil.png';
import Left from './assets/arrow-left.png';
import Right from './assets/arrow-right.png';
import UK from './assets/uk.png';
import ES from './assets/es.png';
import FR from './assets/fr.png';
import HT from './assets/haiti.png';
import Lion from './assets/lion-head.png';
import Football from './assets/football.png';
import Banana from './assets/banana.png';
import Water from './assets/water.png';
import Friends from './assets/friends.png';
import Africa from './assets/africa.png';
import ReadingRoom from './assets/reading-room.png';
import Books from './assets/books.png';

export const Icon = ({ variant }) => {
  switch (variant) {
    case 'close':
      return <Close />;
    case 'info':
      return <Info />;
    case 'placeholder':
      return <Placeholder />;
    case 'search':
      return <Search />;
    case 'check':
      return <Check />;
    case 'dash':
      return <Dash />;
    case 'ellipse':
      return <Ellipse />;
    case 'eye-open':
      return <EyeOpen />;
    case 'eye-closed':
      return <EyeClosed />;
    case 'menu':
      return <Menu />;
    case 'valid':
      return <Valid />;
    case 'invalid':
      return <Invalid />;
    case 'warning':
      return <Warning />;
    case 'question':
      return <Question />;
    case 'down':
      return <Down />;
    case 'bookstack':
      return <Bookstack />;
    case 'calendar-blue':
      return <CalendarBlue />;
    case 'calendar-red':
      return <CalendarRed />;
    case 'check-red':
      return <CheckRed />;
    case 'check-white':
      return <CheckWhite />;
    case 'classroom':
      return <Classroom />;
    case 'coin':
      return <Coin />;
    case 'coins':
      return <Coins />;
    case 'donate-red':
      return <DonateRed />;
    case 'donate-white':
      return <DonateWhite />;
    case 'email-red':
      return <EmailRed />;
    case 'email-white':
      return <EmailWhite />;
    case 'globe':
      return <Globe />;
    case 'goal':
      return <Goal />;
    case 'grad':
      return <Grad />;
    case 'haiti':
      return <Haiti />;
    case 'location':
      return <Location />;
    case 'nav-left':
      return <NavLeft />;
    case 'nav-right':
      return <NavRight />;
    case 'openbook':
      return <OpenBook />;
    case 'pages':
      return <Pages />;
    case 'speech-blue':
      return <SpeechBlue />;
    case 'speech-white':
      return <SpeechWhite />;
    case 'tablet':
      return <Tablet />;
    case 'words':
      return <Words />;
    case 'pencil':
      return <img alt="left" src={Pencil} />;
    case 'rocket':
      return <img alt="left" src={Rocket} />;
    case 'arrow-left':
      return <img alt="left" src={Left} />;
    case 'arrow-right':
      return <img alt="right" src={Right} />;
    case 'en-UK':
      return <img alt="UK" src={UK} />;
    case 'es-ES':
      return <img alt="ES" src={ES} />;
    case 'fr-FR':
      return <img alt="FR" src={FR} />;
    case 'cr-HT':
      return <img alt="FR" src={HT} />;
    case 'lion':
      return <img alt="lion" src={Lion} />;
    case 'football':
      return <img alt="football" src={Football} />;
    case 'water':
      return <img alt="water" src={Water} />;
    case 'banana':
      return <img alt="banana" src={Banana} />;
    case 'friends':
      return <img alt="friends" src={Friends} />;
    case 'africa':
      return <img alt="africa" src={Africa} />;
    case 'reading-room':
      return <img alt="reading-room" src={ReadingRoom} />;
    case 'books':
      return <img alt="books" src={Books} />;
    default:
      return null;
  }
};

Icon.defaultProps = {
  variant: 'placeholder',
};

Icon.propTypes = {
  variant: PropTypes.oneOf([
    'arrow-left',
    'arrow-right',
    'books',
    'close',
    'info',
    'search',
    'check',
    'dash',
    'ellipse',
    'eye-open',
    'eye-closed',
    'menu',
    'valid',
    'invalid',
    'placeholder',
    'warning',
    'question',
    'en-UK',
    'fr-FR',
    'es-ES',
    'lion',
    'football',
    'water',
    'banana',
    'friends',
    'africa',
    'reading-room',
    'down',
    'bookstack',
    'calendar-blue',
    'calendar-red',
    'check-red',
    'check-white',
    'classroom',
    'coin',
    'coins',
    'donate-red',
    'donate-white',
    'email-red',
    'email-white',
    'globe',
    'goal',
    'grad',
    'haiti',
    'location',
    'nav-left',
    'nav-right',
    'openbook',
    'pages',
    'pencil',
    'rocket',
    'speech-blue',
    'speech-white',
    'tablet',
    'words',
  ]),
};
