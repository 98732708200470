import styled from 'styled-components';

export const Bar = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  > svg {
    position: absolute;
    left: 16px;
    top: 20px;
  }
`;

export const Search = styled.input`
  background-color: ${p => p.theme.color.red100};
  border: 2px solid ${p => p.theme.color.black300};
  border-radius: 24px;
  width: 100%;
  min-width: 160px;
  height: 48px;
  margin: 8px 0;
  padding: 16px;
  padding-left: 46px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  font-family: ${p => p.theme.font.sans};
  transition: all 300ms ease;

  &:hover {
    border-color: ${p => p.theme.color.red500};
  }

  @media ${p => p.theme.device.desktop} {
    min-width: 220px;
  }
`;
