import { createSelector } from '@reduxjs/toolkit';

import { initialState, sliceKey } from './slice';

const selectDomain = state => state[sliceKey] || initialState;

export const selectBookQueue = createSelector(
  [selectDomain],
  state => state.bookQueue,
);

export const selectSelectAll = createSelector(
  [selectDomain],
  state => state.selectAll,
);

export const selectFilterModal = createSelector(
  [selectDomain],
  state => state.modal,
);

export const selectFilters = createSelector([selectDomain], state => ({
  language: state.language,
  source: state.source,
  type: state.type,
  level: state.level,
  words: state.total_words,
  topic: state.topic,
  search: state.search,
}));

export const selectFilterLanguage = createSelector(
  [selectDomain],
  state => state.language,
);

export const selectFilterSource = createSelector(
  [selectDomain],
  state => state.source,
);

export const selectFilterType = createSelector(
  [selectDomain],
  state => state.type,
);

export const selectFilterLevel = createSelector(
  [selectDomain],
  state => state.level,
);

export const selectFilterWords = createSelector(
  [selectDomain],
  state => state.total_words,
);

export const selectFilterTopic = createSelector(
  [selectDomain],
  state => state.topic,
);

export const selectLibrary = createSelector(
  [selectDomain],
  state => state.library,
);

export const selectSearch = createSelector(
  [selectDomain],
  state => state.search,
);
