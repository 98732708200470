import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.Home.header',
    defaultMessage:
      'We bring the joy and power of reading to children in Haiti',
  },
  pitch: {
    id: 'app.containers.Home.pitch',
    defaultMessage: `
      Our free digital library app has open-sourced books in French and Creole, videos of books being read aloud, and songs with lyrics.
      It can be downloaded and used in schools with or without the internet. Click the link below and take a look!
    `,
  },
  explore: {
    id: 'app.containers.Home.explore',
    defaultMessage: 'Explore our library app',
  },
  download: {
    id: 'app.containers.Home.download',
    defaultMessage: 'Download',
  },
  downloadHeader: {
    id: 'app.containers.Home.downloadHeader',
    defaultMessage: 'Select categories:',
  },
  install: {
    id: 'app.containers.Home.install',
    defaultMessage: 'Install our library app',
  },
  userGuide: {
    id: 'app.containers.Home.user-guide',
    defaultMessage: 'User Guide',
  },
  tileHeader: {
    id: 'app.containers.Home.tile-header',
    defaultMessage: 'Our Free Library App Has:',
  },
  tileBooks: {
    id: 'app.containers.Home.tile-books',
    defaultMessage: `Over 2,000 Books in Creole, French, Spanish, and English`,
  },
  tileWorld: {
    id: 'app.containers.Home.tile-world',
    defaultMessage: 'From All Over the World',
  },
  tileHaiti: {
    id: 'app.containers.Home.tile-haiti',
    defaultMessage: 'For Haitian Schools',
  },
  donateHeader: {
    id: 'app.containers.Home.donate-header',
    defaultMessage: 'Help Support Our Mission',
  },
  donate: {
    id: 'app.containers.Nav.donate',
    defaultMessage: 'Donate',
  },
  tabletHeader: {
    id: 'app.containers.Home.tablet-header',
    defaultMessage: `Free Library App`,
  },
  technologyHeader: {
    id: 'app.containers.Home.technology-header',
    defaultMessage: `Technology is a Game Changer`,
  },
  thriveHeader: {
    id: 'app.containers.Home.thrive-header',
    defaultMessage: `To Thrive as Readers, Haitian Children Need`,
  },
  meetHeader: {
    id: 'app.containers.Home.meet-header',
    defaultMessage: `How We Meet Those Needs`,
  },
  teamHeader: {
    id: 'app.containers.Home.team-header',
    defaultMessage: `Our Team`,
  },
  teamDirector: {
    id: 'app.containers.Home.team-director',
    defaultMessage: `Director of the Reading Room`,
  },
  teamCofounder: {
    id: 'app.containers.Home.team-cofounder',
    defaultMessage: `Co-founder of the Reading Room`,
  },
  learn: {
    id: 'app.containers.Home.learn',
    defaultMessage: `Learn More`,
  },
  futureHeader: {
    id: 'app.containers.Home.future-header',
    defaultMessage: `Let's Create a Better Future for Children in Haiti`,
  },
  future: {
    id: 'app.containers.Home.future',
    defaultMessage: `We need funds to continue to develop and expand The Reading Room digital library app. We are constantly adding new open-sourced books,
      read aloud videos, song videos with lyrics and other material to the app. We believe it is transformational for children to have access to thousands of
      reading resources right at their fingertips. In addition, we continue to acquire and ship physical books to our two flash libraries.
      This is all in fulfilment of our mission to bring the joy and power of reading to children in Haiti.
    `,
  },
});
