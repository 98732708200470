import styled from 'styled-components';

export const ContentWrapper = styled.nav`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${p => p.theme.color.white100};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow-y: auto;

  #mydiv {
    align-self: flex-start;
    text-align: left;
  }

  #theirdiv {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between; /* Ensure space between the two elements */
    align-items: center; /* Optional: Center items vertically */
  }

  .left-item {
    margin-right: auto; /* Optional: additional margin if needed */
  }

  .right-item {
    margin-left: auto; /* Optional: additional margin if needed */
  }
`;

export const FiltersWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 0;
`;