import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Typography } from 'components/atoms/Typography';
import { BaseModal } from 'components/molecules/Modal';
import { FilterOptions } from 'components/molecules/FilterOptions';
import { ContentWrapper, ButtonsWrapper } from './wrappers';
import { filterOptions } from './constants';
import messages from '../messages';

const FilterModal = ({ isOpen, onClose, filter, ...props }) => (
  <BaseModal maxWidth="656px" {...{ isOpen, onClose, ...props }}>
    <ContentWrapper>
      <Typography
        label={!!filter && <FormattedMessage {...messages[filter]} />}
        variant="h2"
      />
      <ButtonsWrapper>
        <FilterOptions
          label={filter}
          options={filterOptions[filter]}
          {...{ onClose }}
        />
      </ButtonsWrapper>
    </ContentWrapper>
  </BaseModal>
);

FilterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
};

export default FilterModal;
