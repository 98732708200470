import React from 'react';
import PropTypes from 'prop-types';
import { PictureWrapper, Wrapper } from './wrappers';

const Carousel = ({ images, variant }) => (
  <Wrapper {...{ variant }}>
    {images.map(image => (
      <PictureWrapper alt={image.title} src={image.src} {...{ variant }} />
    ))}
  </Wrapper>
);

Carousel.defaultProps = {
  images: [],
  variant: 'small',
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.string,
};

export default Carousel;
