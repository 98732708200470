import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from 'data/language/selectors';
import { actions as languageActions } from 'data/language/slice';

import { Button } from 'components/atoms/Button';
import { Typography } from 'components/atoms/Typography';
import LangModal from './LangModal';

import {
  ContentWrapper,
  LangButtonWrapper,
  HeaderWrapper,
  LinksWrapper,
  RightWrapper,
  Wrapper,
} from './wrappers';
import messages from './messages';

const Nav = () => {
  const locale = useSelector(selectLocale);
  const localeString = loc => {
    switch (loc) {
      case 'en-UK':
        return 'EN';
      case 'fr-FR':
        return 'FR';
      case 'cr-HT':
        return 'HT';
      case 'es-ES':
        return 'ES';
      default:
        return '';
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [changeLang, setChangeLang] = useState(false);
  const changeLocale = newLocale => {
    dispatch(languageActions.applyLocale(newLocale));
  };
  const [deep, isDeep] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', e => {
      const scrollCheck = window.scrollY > 60;
      if (scrollCheck && !deep) {
        isDeep(true);
      }
      if (!scrollCheck && deep) {
        isDeep(false);
      }
    });
  }, [deep]);
  return (
    <Wrapper {...{ deep }}>
      <ContentWrapper>
        <RightWrapper>
          <Button
            variant="primary"
            iconLeft="donate-white"
            onClick={() =>
              window.open(
                'https://www.paypal.com/donate/?hosted_button_id=KJUY73PTWCLWC',
                '_blank',
              )
            }
            label={<FormattedMessage {...messages.donate} />}
          />
          <LangButtonWrapper>
            <Button
              variant="icon-sm"
              iconLeft={locale}
              iconRight="down"
              onClick={() => setChangeLang(true)}
              label={localeString(locale)}
            />
          </LangButtonWrapper>
        </RightWrapper>
        <HeaderWrapper onClick={() => navigate('/')}>
          <Button variant="icon-lg" iconLeft="reading-room" label="" />
          <Typography
            variant="h3"
            as="h3"
            label={<FormattedMessage {...messages.navHeader} />}
            onClick={() => navigate('/')}
          />
        </HeaderWrapper>
        <LinksWrapper>
          <Button
            variant="link"
            size="sm"
            label={<FormattedMessage {...messages.library} />}
            onClick={() => navigate('library')}
          />
          <Button
            variant="link"
            size="sm"
            label={<FormattedMessage {...messages.about} />}
            onClick={() => navigate('about-us')}
          />
          <Button
            variant="link"
            size="sm"
            label={<FormattedMessage {...messages.help} />}
            onClick={() => navigate('how-to-help')}
          />
        </LinksWrapper>
        <LangModal
          isOpen={changeLang}
          onClose={() => setChangeLang(false)}
          {...{ changeLocale }}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default Nav;
