export const base64ToBlob = (base64, mimeType) => {
  const binaryString = window.atob(base64);

  // Convert the binary string to a character array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create and return a blob from the bytes
  return new Blob([bytes], { type: mimeType });
};
