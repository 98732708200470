import { defineMessages } from 'react-intl';

export default defineMessages({
  logo: {
    id: 'app.containers.Nav.header',
    defaultMessage: 'The Reading Room',
  },
  tagline: {
    id: 'app.containers.Home.header',
    defaultMessage:
      'We bring the joy and power of reading to children in Haiti',
  },
  address1: {
    id: 'app.containers.Footer.address1',
    defaultMessage: '35 Rustic Lane',
  },
  address2: {
    id: 'app.containers.Footer.address2',
    defaultMessage: 'South Windsor, CT',
  },
  address3: {
    id: 'app.containers.Footer.address3',
    defaultMessage: '06074 USA',
  },
  email: {
    id: 'app.containers.Footer.email',
    defaultMessage: 'readingroomhaiti@cox.net',
  },
  copyright: {
    id: 'app.containers.Footer.copyright',
    defaultMessage: 'Copyright © 2022 The Reading Room. All Rights Reserved.',
  },
});
