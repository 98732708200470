import styled from 'styled-components';
import { FixedSizeGrid } from 'react-window';

export const Wrapper = styled.div`
  display: flex;
  flex: auto;
  flex-grow: 1;
  width: 100%; // ${p => p.bookPaneWidth};
  margin-top: -24px;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* hide scrollbar for chrome, safari and opera */
  ::-webkit-scrollbar {
    display: none;
  }

  @media ${p => p.theme.device.tablet} {
    margin-left: 24px;
  }

  @media ${p => p.theme.device.desktop} {
    margin-top: 0;
  }
`;

export const Grid = styled(FixedSizeGrid)`
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* hide scrollbar for chrome, safari and opera */
  ::-webkit-scrollbar {
    display: none;
  }

  scroll-behavior: ${p => p.scrollStyle.current};
`;

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100svh;
  align-items: center;
  justify-content: center;

  @media ${p => p.theme.device.tablet} {
    margin-left: 24px;
  }
`;
