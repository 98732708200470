export const filterOptions = {
  language: ['french', 'creole', 'english', 'spanish', 'wordless'],
  level: [
    'beginning',
    'early',
    'middle',
    'late',
    'earlyMiddle',
    'lateMiddle',
    'high',
  ],
  source: [
    'storyweaver',
    'africanStorybook',
    'davidRevoy',
    'bookdash',
    'mustardSeed',
    'bibleForChildren',
    'unesco',
    'storybooksCanada',
    'bookbot',
    'bookSprint',
  ],
  topic: [
    'adventure',
    'fantasy',
    'mystery',
    'otherFiction',
    'animals',
    'birds',
    'cats',
    'dogs',
    'fish',
    'insects',
    'environment',
    'nature',
    'water',
    'culture',
    'family',
    'feelings',
    'food',
    'friends',
    'school',
    'sports',
    'biography',
    'history',
    'math',
    'religion',
    'science',
    'otherNonfiction',
  ],
  type: [
    'fiction',
    'nonFiction',
    'graphicNovels',
    'bookSeries',
    'decodableTexts',
  ],
  words: [25, 50, 100, 500, 1000, 2500, '2500+'],
};
