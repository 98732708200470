import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.HelpOut.header',
    defaultMessage: 'How to Help',
  },
  expand: {
    id: 'app.containers.HelpOut.expand',
    defaultMessage: 'We need support to expand our library app',
  },
  funds: {
    id: 'app.containers.HelpOut.funds',
    defaultMessage: `We need funds to continue to develop and expand The Reading Room digital library app. We are constantly adding new open-sourced
    books, read aloud videos, song videos with lyrics and other material to the app. We believe it is transformational for children to have access
    to thousands of reading resources right at their fingertips. In addition, we continue to acquire and ship physical books to our two flash
    libraries.`,
  },
  add: {
    id: 'app.containers.HelpOut.add',
    defaultMessage: '${amount} allows us to add', //eslint-disable-line
  },
  books: {
    id: 'app.containers.HelpOut.books',
    defaultMessage: '{amount} Books',
  },
  stay: {
    id: 'app.containers.HelpOut.stay',
    defaultMessage: 'Books stay in the app',
  },
  permanently: {
    id: 'app.containers.HelpOut.permanently',
    defaultMessage: 'Permanently',
  },
  fulfillment: {
    id: 'app.containers.HelpOut.fulfillment',
    defaultMessage:
      'This is all in fulfillment of our mission to bring the joy and power of reading to children in Haiti.',
  },
  donate: {
    id: 'app.containers.HelpOut.donate',
    defaultMessage: 'Donate by clicking the button:',
  },
  donateClick: {
    id: 'app.containers.Nav.donate',
    defaultMessage: 'Donate',
  },
  check: {
    id: 'app.containers.HelpOut.check',
    defaultMessage: 'Or you can mail a check to:',
  },
  readingRoom: {
    id: 'app.containers.Nav.header',
    defaultMessage: 'The Reading Room',
  },
  address1: {
    id: 'app.containers.Footer.address1',
    defaultMessage: '35 Rustic Lane',
  },
  address2: {
    id: 'app.containers.Footer.address2',
    defaultMessage: 'South Windsor, CT',
  },
  address3: {
    id: 'app.containers.Footer.address3',
    defaultMessage: '06074 USA',
  },
  questions: {
    id: 'app.containers.HelpOut.questions',
    defaultMessage: 'For any questions reach out to:',
  },
  efficiently: {
    id: 'app.containers.HelpOut.efficiently',
    defaultMessage: 'We use donations as efficiently as possible',
  },
});
