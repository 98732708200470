import { defineMessages } from 'react-intl';

export default defineMessages({
  navHeader: {
    id: 'app.containers.Nav.header',
    defaultMessage: 'The Reading Room',
  },
  filters: {
    id: 'app.components.organisms.FiltersBar.filters',
    defaultMessage: 'Filters',
  },
  clearFilters: {
    id: 'app.components.organisms.FiltersBar.clear-filters',
    defaultMessage: 'Reset all filters',
  },
  search: {
    id: 'app.components.molecules.FilterGroup.filter-search',
    defaultMessage: 'Title or Author',
  },
  level: {
    id: 'app.components.molecules.FilterGroup.filter-level',
    defaultMessage: 'Level',
  },
  levels: {
    id: 'app.components.molecules.FilterGroup.filter-levels',
    defaultMessage: 'Level ({number})',
  },
  source: {
    id: 'app.components.molecules.FilterGroup.filter-source',
    defaultMessage: 'Source',
  },
  sources: {
    id: 'app.components.molecules.FilterGroup.filter-sources',
    defaultMessage: 'Source ({number})',
  },
  topic: {
    id: 'app.components.molecules.FilterGroup.filter-topic',
    defaultMessage: 'Topic',
  },
  topics: {
    id: 'app.components.molecules.FilterGroup.filter-topics',
    defaultMessage: 'Topic ({number})',
  },
  type: {
    id: 'app.components.molecules.FilterGroup.filter-type',
    defaultMessage: 'Type',
  },
  types: {
    id: 'app.components.molecules.FilterGroup.filter-types',
    defaultMessage: 'Type ({number})',
  },
  language: {
    id: 'app.components.molecules.FilterGroup.filter-language',
    defaultMessage: 'Language',
  },
  languages: {
    id: 'app.components.molecules.FilterGroup.filter-languages',
    defaultMessage: 'Language ({number})',
  },
  words: {
    id: 'app.components.molecules.FilterGroup.filter-length',
    defaultMessage: 'Length',
  },
  lengths: {
    id: 'app.components.molecules.FilterGroup.filter-lengths',
    defaultMessage: 'Length ({number})',
  },
});
